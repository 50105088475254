
export default {
  props: {
    slice: { type: Object, required: true },
  },
  computed: {
    stages() {
      return [
        {
          heading:
            this.slice.primary.orgs_stage_1_heading ||
            this.defaults.orgs_stage_1_heading,
          text:
            this.slice.primary.orgs_stage_1_text ||
            this.defaults.orgs_stage_1_text,
        },
        {
          heading:
            this.slice.primary.orgs_stage_2_heading ||
            this.defaults.orgs_stage_2_heading,
          text:
            this.slice.primary.orgs_stage_2_text ||
            this.defaults.orgs_stage_2_text,
        },
        {
          heading:
            this.slice.primary.orgs_stage_3_heading ||
            this.defaults.orgs_stage_3_heading,
          text:
            this.slice.primary.orgs_stage_3_text ||
            this.defaults.orgs_stage_3_text,
        },
        {
          heading:
            this.slice.primary.orgs_stage_4_heading ||
            this.defaults.orgs_stage_4_heading,
          text:
            this.slice.primary.orgs_stage_4_text ||
            this.defaults.orgs_stage_4_text,
        },
      ]
    },
    defaults() {
      return {
        orgs_stage_1_heading: 'Preparing your role',
        orgs_stage_1_text:
          'We work with you to understand the competencies that the successful candidate must have, as well as the culture and values of your organisation.',
        orgs_stage_2_heading: 'Sourcing candidates',
        orgs_stage_2_text:
          'We cast the net wider. We notify all members who want to hear about roles like yours. If the role is a perfect fit, passionate candidates put themselves forward. If not, members can recommend great candidates from their own networks.',
        orgs_stage_3_heading: 'Creating your shortlist',
        orgs_stage_3_text:
          'Candidates provide detailed evidence against each of your required competencies. We can handle feedback and rejections, leaving you to interview the strongest candidates.',
        orgs_stage_4_heading: 'Finding the perfect fit',
        orgs_stage_4_text:
          'Our simple, secure online process ensures candidates can be thoroughly evaluated. Up to date information is always available, allowing the best candidate to stand out. The result is that you make exceptional hires from talent that only we can source.',
      }
    },
  },
}
